// vue
var login_app = new Vue({
    el: "#login_app",
    data: {
        user_name: "company"
    }
});

// sticky header
var header = u("#js-global-header");
var target_h = 100;
window.onscroll = function() {
    var scrollTop = window.pageYOffset;
    if (scrollTop > target_h) {
        header.addClass("scrolled");
    } else {
        header.removeClass("scrolled");
    }
};

// ScrollReveal
window.sr = ScrollReveal();
sr.reveal(
    ".js-fadein",
    {
        origin: "bottom",
        distance: "30px",
        opacity: 0.1,
        reset: true,
        viewFactor: 0.2
    },
    500
);

sr.reveal(
    ".js-fadein-top",
    {
        origin: "top",
        distance: "30px",
        delay: 500,
        opacity: 0,
        reset: true,
        viewFactor: 0.5
    },
    500
);

sr.reveal(
    ".js-resolve",
    {
        origin: "top",
        distance: "30px",
        delay: 300,
        opacity: 0,
        reset: true,
        viewFactor: 0.5
    },
    500
);

sr.reveal(
    ".js-result",
    {
        origin: "top",
        distance: "30px",
        delay: 500,
        opacity: 0,
        reset: true,
        viewFactor: 0.5
    },
    500
);

sr.reveal(
    ".js-fadein-left",
    {
        origin: "left",
        distance: "30px",
        delay: 300,
        opacity: 0,
        reset: true,
        viewFactor: 0.5
    },
    500
);

sr.reveal(".js-fadein-right", {
    origin: "right",
    distance: "30px",
    delay: 300,
    opacity: 0,
    reset: true,
    viewFactor: 0.5
});

sr.reveal(
    ".js-pop",
    {
        distance: "0px",
        delay: 500,
        opacity: 0,
        reset: true,
        viewFactor: 0.5
    },
    500
);

sr.reveal(
    ".js-pop-2s",
    {
        distance: "60px",
        delay: 2000,
        opacity: 0,
        reset: true,
        viewFactor: 0.5
    },
    500
);

sr.reveal(".js-function-left", {
    origin: "left",
    distance: "30px",
    delay: 300,
    opacity: 0,
    reset: true,
    viewFactor: 0.2
});

sr.reveal(".js-function-right", {
    origin: "right",
    distance: "30px",
    delay: 300,
    opacity: 0,
    reset: true,
    viewFactor: 0.2
});

sr.reveal(
    ".js-function",
    {
        origin: "bottom",
        distance: "30px",
        opacity: 0.1,
        reset: true,
        viewFactor: 0.5
    },
    500
);

sr.reveal(
    ".js-contact",
    {
        origin: "top",
        distance: "30px",
        delay: 500,
        opacity: 0,
        reset: true,
        viewFactor: 0.5
    },
    500
);

// scroll option
UIkit.scroll(".scroll-offset", {
    offset: 60
});

// YouTube API
function onPlayerReady(t) {
    t.target.mute(),
    t.target.playVideo();
    // $("#play").click(function() {
    //     t.target.playVideo();
    // });
}
function onPlayerReady2(t) {
    t.target.mute(),
    t.target.playVideo();
    // $("#play").click(function() {
    //     t.target.playVideo();
    // });
}
var tag = document.createElement("script");
tag.src = "https://www.youtube.com/iframe_api";
var firstScriptTag = document.getElementsByTagName("script")[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

var ytPlayer;
var ytPlayer2;
function onYouTubeIframeAPIReady() {
    ytPlayer = new YT.Player(
        "youtube-api", // 埋め込む場所の指定
        {
            videoId: "GgozWL-DCF0", // YouTubeのID
            // パラメータの設定
            playerVars: {
                autoplay: 1, // 自動再生する
                rel: 0, // 再生終了後に関連動画を表示しない
                loop: 1, // 繰り返し再生
                playsinline: 1, // iOSでインライン再生
                showinfo: 0, // 情報非表示
                controls: 0, // コントロール非表示
                modestbranding: 1, //YouTubeロゴ非表示
                playlist: "ldapFTFEyzs"
                // playlist: "GgozWL-DCF0"
            },
            events: {
                onReady: onPlayerReady
            }
        }
    );

    ytPlayer2 = new YT.Player(
        "more-movie-youtube", // 埋め込む場所の指定
        {
            videoId: "GgozWL-DCF0", // YouTubeのID
            // パラメータの設定
            playerVars: {
                autoplay: 1, // 自動再生する
                rel: 0, // 再生終了後に関連動画を表示しない
                loop: 1, // 繰り返し再生
                playsinline: 1, // iOSでインライン再生
                showinfo: 0, // 情報非表示
                controls: 1, // コントロール非表示
                modestbranding: 1, //YouTubeロゴ非表示
                playlist: "ldapFTFEyzs"
            },
            events: {
                onReady: onPlayerReady2
            }
        }
    );
}

// slider
var mySwiper = new Swiper(".swiper-container", {
    // Optional parameters
    // loop: true,
    slidesPerView: 3,
    spaceBetween: 25,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true
    },
    breakpoints: {
        480: {
            slidesPerView: 1,
            spaceBetween: 20
        },
        // when window width is <= 640px
        640: {
            slidesPerView: 2
        }
    }
});
